/* eslint-disable camelcase */
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';

import ITariff, {EditTariffType} from '../../../../models/ITariff';

import FormGroupWithError from '../../../UI/FormGroup/FormGroupWithError';
import Checkbox from '../../../UI/Checkbox/Checkbox';

import {tariffAPI} from '../../../../api/tariffAPI';
import FormGroup from '../../../UI/FormGroup/FormGroup';
import PrimaryButton from '../../../UI/Button/PrimaryButton';

import InputName from '../../../UI/Input/InputName';

import InputNumber from '../../../UI/Input/InputNumber';

import ModalBodyWrapper from '../../../UI/Modal/ModalBodyWrapper';

import styles from './TariffForm.module.scss';

interface ITariffFormProps{
    onSuccess: () => void,
    item ?: ITariff,
}

const TariffForm: React.FC<ITariffFormProps> = ({onSuccess, item}) => {
    const [addTariff, {isLoading: isLoadingCreate}] = tariffAPI.useAddTariffMutation();
    const [updateTariff, {isLoading: isLoadingUpdate}] = tariffAPI.useUpdateTariffMutation();
    const {register, handleSubmit, formState, control, setValue} = useForm<EditTariffType>({
        mode: 'onChange', defaultValues: {
            monitor_ssl_enabled: item?.monitor_ssl_enabled ?? false,
            monitor_domain_watch_enabled: item?.monitor_domain_watch_enabled ?? false,
            monitor_ssl_redirect_watch_enabled: item?.monitor_ssl_redirect_watch_enabled ?? false,
            is_archive: item?.is_archive ?? false,
            is_recommended: item?.is_recommended ?? false,
            monitor_extended_methods: item?.monitor_extended_methods ?? false,
            monitor_parse_errors: item?.monitor_parse_errors ?? false,
            monitor_frequent_checking: item?.monitor_frequent_checking ?? false,
            monitor_content_enabled: item?.monitor_content_enabled ?? false,
        },
    });
    const {errors, isValid, isDirty} = formState;

    const isNew = !item;

    const onSubmit: SubmitHandler<EditTariffType> = async({
        price,
        period,
        instances_managed_limit,
        monitor_min_check_interval_limit,
        monitor_max_count_limit,
        ...rest
    }) => {
        const data: EditTariffType = {
            price: Number(price),
            period: Number(period),
            instances_managed_limit: Number(instances_managed_limit),
            monitor_min_check_interval_limit: Number(monitor_min_check_interval_limit),
            monitor_max_count_limit: Number(monitor_max_count_limit),
            ...rest,
        };
        if (isNew){
            await addTariff(data).unwrap();
        }else{
            await updateTariff({
                id: item?.id,
                ...data,
            }).unwrap();
        }

        onSuccess();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <ModalBodyWrapper>
                <FormGroupWithError
                    error={errors?.name?.message}
                    label="Название"
                >
                    <InputName
                        defaultValue={item?.name}
                        control={control}
                        name="name"
                        placeholder="Укажите название"
                        register={register}
                        required
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.price?.message}
                    label="Стоимость, руб"
                >
                    <InputNumber
                        min={0}
                        name="price"
                        defaultValue={item?.price ?? 0}
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.period?.message}
                    label="Срок действия, дней"
                >
                    <InputNumber
                        type="number"
                        min={0}
                        name="period"
                        defaultValue={item?.period ?? 0}
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.monitor_min_check_interval_limit?.message}
                    label="Мин. интервал мониторинга"
                >
                    <InputNumber
                        min={1}
                        name="monitor_min_check_interval_limit"
                        defaultValue={item?.monitor_min_check_interval_limit ?? 1}
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.monitor_max_count_limit?.message}
                    label="Кол-во активных мониторов"
                >
                    <InputNumber
                        min={0}
                        name="monitor_max_count_limit"
                        defaultValue={item?.monitor_max_count_limit ?? 0}
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError
                    error={errors?.telegram_chats_max_count_limit?.message}
                    label="Кол-во активных Telegram чатов"
                >
                    <InputNumber
                        min={0}
                        name="telegram_chats_max_count_limit"
                        defaultValue={item?.telegram_chats_max_count_limit ?? 0}
                        control={control}
                        setValue={setValue}
                    />
                </FormGroupWithError>

                <FormGroupWithError error={errors?.monitor_ssl_enabled?.message}>
                    <Checkbox
                        {...register('monitor_ssl_enabled')}
                    >
                    Проверка SSL
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.monitor_domain_watch_enabled?.message}>
                    <Checkbox
                        {...register('monitor_domain_watch_enabled')}
                    >
                    Мониторинг домена
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.monitor_ssl_redirect_watch_enabled?.message}>
                    <Checkbox
                        {...register('monitor_ssl_redirect_watch_enabled')}
                    >
                    Проверка редиректа с http на https
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.monitor_extended_methods?.message}>
                    <Checkbox
                        {...register('monitor_extended_methods')}
                    >
                    Расширенные методы мониторинга
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.monitor_parse_errors?.message}>
                    <Checkbox
                        {...register('monitor_parse_errors')}
                    >
                    Разбор проблем мониторинга
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.monitor_frequent_checking?.message}>
                    <Checkbox
                        {...register('monitor_frequent_checking')}
                    >
                    Учащенный мониторинг
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.monitor_content_enabled?.message}>
                    <Checkbox
                        {...register('monitor_content_enabled')}
                    >
                        Проверка контента
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.is_archive?.message}>
                    <Checkbox
                        {...register('is_archive')}
                    >
                    Архивный
                    </Checkbox>
                </FormGroupWithError>

                <FormGroupWithError error={errors?.is_recommended?.message}>
                    <Checkbox
                        {...register('is_recommended')}
                    >
                    Рекомендуемый
                    </Checkbox>
                </FormGroupWithError>
            </ModalBodyWrapper>
            <FormGroup>
                <PrimaryButton
                    type="submit"
                    loading={isLoadingCreate || isLoadingUpdate}
                    className="full-width"
                    disabled={!(isValid && (isDirty || isNew))}
                >
                    {isNew ? 'Добавить' : 'Изменить'}
                </PrimaryButton>
            </FormGroup>
        </form>
    );
};

export default TariffForm;