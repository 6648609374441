import React, {SyntheticEvent, useCallback, useState} from 'react';
import {SelectPicker} from 'rsuite';
import {SelectPickerProps} from 'rsuite/esm/SelectPicker/SelectPicker';

import {Control, Controller, RegisterOptions, useController} from 'react-hook-form';

import cn from 'classnames';

import {getInputState} from '../../../utils/inputHelper';

import Correct from '../../Icons/Correct';
import Wrong from '../../Icons/Wrong';
import Edit from '../../Icons/Edit';

import styles from './Select.module.scss';

interface ISelectProps extends SelectPickerProps<number|string>{
    control: Control<any>,
    name: string,
    rules?: RegisterOptions,
    // eslint-disable-next-line no-unused-vars
    onChange?: (value: string | number | null, event: SyntheticEvent<Element, Event>) => void,
    hideIcon?: boolean,
}

const Select: React.FC<ISelectProps> = ({
    cleanable,
    control,
    name,
    rules,
    onChange,
    placeholder,
    hideIcon,
    ...rest
}) => {
    const { fieldState } = useController({name, control});

    const [isFocused, setIsFocused] = useState(false);
    const state = getInputState(fieldState, isFocused, !!rest.defaultValue);

    const iconsMap = {
        valid: <Correct/>,
        error: <Wrong/>,
        active: <Edit color="#000000" />,
        default: <Edit />,
    };

    const classMap = {
        valid: styles.select_valid,
        error: styles.select_error,
        active: styles.select_active,
        default: styles.select_default,
    };

    const onOpen = useCallback(() => {
        setIsFocused(true);
    }, []);

    const onClose = useCallback(() => {
        setIsFocused(false);
    }, []);


    return (
        <div className={styles.select_container + ' ' + (hideIcon ? styles.select_no_icon_select : '')}>
            {!hideIcon && <div className={styles.icon}>
                    {iconsMap[state]}
                </div>
            }
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={rest.defaultValue}
                /* eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars */
                render={({field: {ref, onChange: onChangeFrom, ...field}}) => (
                    <SelectPicker
                        className={cn(styles.select, classMap[state])}
                        cleanable={cleanable ?? false}
                        onEnter={onOpen}
                        onExit={onClose}
                        onChange={(value, event) => {
                            onChangeFrom(value);
                            onChange && onChange(value, event);
                        }}
                        placeholder={placeholder ?? 'Выберите...'}
                        {...rest}
                        {...field}
                    />
                )}
            />

        </div>
    );
};


export default React.memo(Select);