import IOrderBy from '../models/IOrderBy';

const makeUrl = (baseUrl: string, page?: number, sort?: IOrderBy, params: string[] = [], limit = 0) => {
    const mutableParams = [...params];
    if (page) {
        mutableParams.push(`page=${page}`);
    }
    if (sort) {
        mutableParams.push(`order[${sort?.field}]=${sort?.direction}`);
    }
    if (limit) {
        mutableParams.push(`limit=${limit}`);
    }

    return mutableParams.length === 0
        ? baseUrl
        : `${baseUrl}?${mutableParams.join('&')}`;
};

export const ApiUrls = {
    TOKEN_REFRESH: '/token/refresh',
    TOKEN: '/token',
    IMPERSONATE: (id: number) => `/impersonate/${id}`,
    EMAIL_RECONFIRM: '/users/email/confirm/send',
    USERS: (page?: number, sort?: IOrderBy, filters?: string[]) => makeUrl('/users', page, sort, filters),
    USER: (id: number) => `/users/${id}`,
    USER_DISABLE_RECURRING: '/users/disable-recurring',
    USER_TARIFF: (id: number) => `/users/${id}/tariff`,
    USER_CONFIRM_EMAIL: (id: number) => `/users/${id}/confirm-email`,
    USER_CHANGE_EMAIL: '/users/change-email',
    ME: '/users/me',
    PASSWORD_RESET_REQUEST: '/users/password-reset/request',
    PASSWORD_RESET_CONFIRM: (userId: number, token: string) => `/users/${userId}/password-reset/confirm/${token}`,
    EMAIL_CONFIRM: (userId: number, token: string) => `/users/${userId}/email/confirm/${token}`,
    CHANGE_EMAIL_CONFIRM: (userId: number, token: string) => `/users/${userId}/email/change-confirm/${token}`,
    SUMMARY_MONITORS: '/summary/monitors',
    MONITORS: (page?: number, sort?: IOrderBy, filters: string[]=[]) => makeUrl('/monitors', page, sort, filters),
    MONITORS_BATCH_ACTION: (action: string) => `/monitors/batch/${action}`,
    MONITORS_UNSUBSCRIBE: (uuid: string) => `/anon/monitors/unsubscribe/${uuid}`,
    MONITOR: (uuid: string) => `/monitors/${uuid}`,
    MONITOR_LOGS: (uuid: string) => `/monitors/${uuid}/logs`,
    MONITOR_AVG_RESPONSE_TIME: (uuid: string) => `/monitors/${uuid}/avg-response-time`,
    MONITOR_LAST_DAY_INCIDENTS_RANGES: (uuid: string) => `/monitors/${uuid}/last-day-incidents-ranges`,
    INCIDENTS: (
        monitorUuid?: string,
        page?: number,
        sort?: IOrderBy,
        limit = 0
    ) => makeUrl('/incidents', page, sort, [monitorUuid ? `monitor.uuid=${monitorUuid}` : ''], limit),
    INCIDENTS_EXPORT: '/incidents/export',
    MONITORS_EXPORT: '/monitors/export',
    WARNINGS: (
        monitorUuid?: string,
        page?: number,
        sort?: IOrderBy,
        limit = 0
    ) => makeUrl('/warnings', page, sort, [monitorUuid ? `monitors.uuid=${monitorUuid}` : '', 'ignore=false'], limit),
    WARNING: (id: number) => `/warnings/${id}`,
    TARIFFS: (sort?: IOrderBy) => makeUrl('/tariffs', 0, sort),
    TARIFF: (tariffId: number) => `/tariffs/${tariffId}`,
    TARIFF_DEFAULT: (tariffId: number) => `/tariffs/${tariffId}/default`,
    // eslint-disable-next-line max-len
    PAYMENTS: (page?:number, sort?: IOrderBy, all?: boolean, filters: string[]=[]) => makeUrl('/payments', page, sort, all ? [...filters, 'all=true'] : filters),
    PAYMENT: (paymentId: number) => `/payments/${paymentId}`,
    PAYMENT_USER_STATS: () => '/payments/users/stats',
    PAYMENT_USER_STATS_PLOT_DATA: (year: number | null) => '/payments/users/stats/plot' + (year ? '?year=' + year : ''),
    TELEGRAM_CHATS: (sort?: IOrderBy, params?: string[]) => makeUrl('/telegram-chats', 0, sort, params),
    TELEGRAM_CHAT: (id: number) => `/telegram-chats/${id}`,
    TELEGRAM_CHAT_SEND_TEST_NOTIFY: (id: number) => `/telegram-chats/test-notify/${id}`,
    BATCH_CHECK: (operationId: string) => `/batch/${operationId}`,
    MONITORS_IDS: '/monitors/ids',
};

export const AppUrls = {
    HOME: '/',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    PASSWORD_RESET: '/password/reset',
    PASSWORD_RESET_SENT: '/password/reset/sent',
    NEW_PASSWORD: '/password/reset/:userId/:token',
    EMAIL_CONFIRMATION: '/email/confirm/:userId/:token',
    CHANGE_EMAIL_CONFIRMATION: '/change-email/:userId/:token',
    FORBIDDEN: '/forbidden',
    SETTINGS: '/settings',
    MONITOR_UNSUBSCRIBE: '/anon/monitors/unsubscribe/:uuid',
    MONITORS: '/monitors',
    PAYMENTS: '/payments',
    TARIFFS: '/tariffs',
    ADMIN_TARIFFS: '/admin/tariffs',
    ADMIN_PAYMENTS: '/admin/payments',
    ADMIN_USERS: '/admin/users',
    MONITOR_LOGS: '/monitors/:monitorId/logs',
    MONITOR_DETAILS: '/monitors/:monitorId/details',
    INCIDENTS: '/incidents',
    WARNINGS: '/warnings',
    SUPPORT: 'https://overseer.observer/support',
    MANUAL_MANAGEMENT: '/manual-management',
    PAYMENT_SUCCESS: '/payment/success',
    PAYMENT_CANCEL: '/payment/cancel',
    PRIVACY_POLICY: 'https://overseer.observer/policy/',
    LICENSE_AGREEMENT: 'https://overseer.observer/license/',
    PAYMENT_OFFER: 'https://overseer.observer/license/#recurring',
    NOT_FOUND: '*',
};

export const ROBOKASSA_PAYMENT_PAGE = (invoiceId: string) => `https://auth.robokassa.ru/Merchant/Index/${invoiceId}`;

export const TELEGRAM_CHAT_IRI = (id: number|string) => `/api/v1/telegram-chats/${id}`;
export const TELEGRAM_CHATS_RESOURCE_NAME = 'telegram-chats';