import React, {useState} from 'react';

import {Control, Controller, FieldErrors, UseFormGetValues, UseFormRegister} from 'react-hook-form';

import {Radio, RadioGroup} from 'rsuite';

import {useSelector} from 'react-redux';

import Checkbox from '../../UI/Checkbox/Checkbox';
import HintPremium from '../../UI/Hint/HintPremium';
import FormGroup from '../../UI/FormGroup/FormGroup';
import HintQuestion from '../../UI/Hint/HintQuestion';
import Select from '../../UI/Input/Select';
import FormGroupWithError from '../../UI/FormGroup/FormGroupWithError';

import InputText from '../../UI/Input/InputText';
import IMonitorOutput from '../../../models/Monitor/IMonitor';
import {userSelector} from '../../../redux/authSlice';
import SliderInput from '../../UI/Slider/SliderInput';
import MonitorContentExtraSetting from './MonitorContentExtraSetting';

interface IExtraSettingsSubformProps {
    item?: IMonitorOutput,
    getValues: UseFormGetValues<any>,
    errors: FieldErrors<any>,
    control: Control<any>,
    register: UseFormRegister<any>,
}

const ExtraSettingsSubform: React.FC<IExtraSettingsSubformProps> = ({
    item,
    getValues,
    errors,
    control,
    register,
}) => {
    const user = useSelector(userSelector);
    const isNew = !item;
    const [isExtendedMethod, setIsExtendedMethod] = useState(item?.method !== 'HEAD' && !isNew);

    const method = item?.method ?? 'HEAD';

    const methods = ['HEAD', 'GET', 'POST'].map(method => ({
        label: method,
        value: method,
    }));
    const isParseErrorsChecked = getValues('parse_errors');
    const isParseErrorsJson = getValues('parse_errors_type') === 'json';


    const isAllowedMethod = (method: string) => method === 'HEAD' || user?.tariff.monitor_extended_methods;

    const httpRequestTimeout = item?.http_request_timeout ?? 30;
    const [httpTimeout, changeTimeout] = useState(httpRequestTimeout);

    return (
        <>
            <div className="form-group d-flex align-items-center">
                <Checkbox
                    disabled={!user?.tariff.monitor_ssl_enabled}
                    {...register('ssl_watch')}
                >
                    Мониторинг SSL
                </Checkbox>
                {
                    !user?.tariff.monitor_ssl_enabled
                    && <HintPremium condition={'monitor_ssl_enabled'}/>
                }
            </div>

            <div className="form-group d-flex align-items-center">
                <Checkbox
                    disabled={!user?.tariff.monitor_domain_watch_enabled}
                    {...register('domain_watch')}
                >
                    Мониторинг срока регистрации домена
                </Checkbox>
                {
                    !user?.tariff.monitor_domain_watch_enabled
                    && <HintPremium condition={'monitor_domain_watch_enabled'}/>
                }
            </div>

            <div className="form-group d-flex align-items-center">
                <Checkbox
                    disabled={!user?.tariff.monitor_ssl_redirect_watch_enabled}
                    {...register('ssl_redirect_watch')}
                >
                    Проверка редиректа с http на https
                </Checkbox>
                {
                    !user?.tariff.monitor_ssl_redirect_watch_enabled
                    && <HintPremium condition={'monitor_ssl_redirect_watch_enabled'}/>
                }
            </div>

            <FormGroup>
                <Checkbox
                    disabled={!user?.tariff.monitor_frequent_checking}
                    {...register('frequent_checking')}
                >
                    Учащенный мониторинг <HintQuestion placement="right">
                    <>
                        {/* eslint-disable-next-line max-len */}
                        <p>Эта опция позволит более точно определять длительность инцидентов, которые длятся до 10
                            минут</p>
                        {/* eslint-disable-next-line max-len */}
                        <p>В случае возникновения инцидента следующий запрос на проверку URL будет отправлен через 5
                            секунд
                            после завершения предыдущей проверки</p>
                        {/* eslint-disable-next-line max-len */}
                        <p>Учащенный мониторинг будет работать в первые 10 минут после возникновения инцидента или до
                            его
                            решения, если это произойдет раньше</p>
                    </>
                </HintQuestion>
                    {
                        !user?.tariff.monitor_frequent_checking
                        && <HintPremium condition={'monitor_frequent_checking'}/>
                    }
                </Checkbox>
            </FormGroup>


            <FormGroup>
                <label>
                    Метод отправки запроса <HintQuestion placement="right">
                        <>
                            {/* eslint-disable-next-line max-len */}
                            <p><strong>HEAD</strong> - запрашиваются только заголовки ответа. Для экономии пропускной
                            способности
                            тело ответа не запрашивается и не сохраняется</p>
                            {/* eslint-disable-next-line max-len */}
                            <p><strong>GET</strong> - запрашиваются заголовки и тело ответа. При возникновении инцидента
                            тело ответа
                            будет сохранено и доступно 30 дней. Возможно включение опции разбора проблем</p>
                            {/* eslint-disable-next-line max-len */}
                            <p><strong>POST</strong> - отправляется POST-запрос с пустым телом. Запрашиваются заголовки и
                            тело
                            ответа. При возникновении инцидента тело ответа будет сохранено и доступно 30 дней. Возможно
                            включение опции разбора проблем</p>
                        </>
                    </HintQuestion>
                    {
                        errors?.method
                        && errors.method.type === 'validate'
                        && <HintPremium condition={'monitor_extended_methods'}/>
                    }
                </label>
                <Select
                    rules={{validate: isAllowedMethod}}
                    control={control}
                    name="method"
                    data={methods}
                    defaultValue={method}
                    placeholder="Выберите метод"
                    cleanable={false}
                    searchable={false}
                    onChange={(value) => {
                        setIsExtendedMethod(value !== 'HEAD');
                    }}
                />
            </FormGroup>

            <FormGroup>
                <label htmlFor="requestTimeout">
                    Таймаут запроса, {httpTimeout} сек. <HintQuestion placement="right">
                        Определяет количество секунд, в течении которого должен быть получен успешный ответ от сервера.
                    </HintQuestion>
                </label>
                <SliderInput
                    step={1}
                    max={60}
                    min={1}
                    defaultValue={httpRequestTimeout}
                    renderMark={mark => {
                        return <span>{[1, 15, 30, 45, 60].includes(mark) ? mark : ''}</span>;
                    }}
                    name="http_request_timeout"
                    control={control}
                    onChange={(value) => {
                        changeTimeout(value);
                    }}
                />
            </FormGroup>

            {
                isExtendedMethod
                && <FormGroupWithError error={errors?.monitor_parse_errors?.message}>
                    <Checkbox
                        disabled={!user?.tariff.monitor_parse_errors}
                        {...register('parse_errors')}
                    >
                        Разбор проблем <HintQuestion placement="right">
                            <>
                                {/* eslint-disable-next-line max-len */}
                                <p>Если вы хотите более детально мониторить причины возникающих проблем, попросите ваших ИТ-специалистов сделать страницу, доступную по секретному URL, на которой будут проверяться основные подсистемы вашего сайта и в случае ошибок возвращаться код <strong>500</strong> и
                                список возникших проблем в указанном ниже формате.</p>
                                {/* eslint-disable-next-line max-len */}
                                <p>Мы рекомендуем отслеживать таким образом доступность базы данных, кэша, очередей, остаток
                                места
                                на диске и состояние оперативной памяти.</p>
                            </>
                        </HintQuestion>
                        {
                            !user?.tariff.monitor_parse_errors
                            && <HintPremium condition={'monitor_parse_errors'}/>
                        }
                    </Checkbox>
                </FormGroupWithError>
            }

            {
                isExtendedMethod
                && isParseErrorsChecked
                && <FormGroup>
                    <label>
                        Способ разбора проблем <HintQuestion placement="right">
                            <>
                                {/* eslint-disable-next-line max-len */}
                                <p><strong>JSON</strong> - страница Вашего сайта возвращает список проблем в виде JSON-массива
                                </p>
                                {/* eslint-disable-next-line max-len */}
                                <p><strong>Регулярное выражение</strong> - страница Вашего сайта возвращает список проблем в
                                виде
                                обычного текста или HTML.</p>
                            </>
                        </HintQuestion>
                    </label>
                    <Controller
                        control={control}
                        name="parse_errors_type"
                        rules={{required: true}}
                        render={({field: {onChange, value, name}}) => (
                            <RadioGroup
                                inline
                                name={name}
                                value={value}
                                onChange={value => onChange(value)}
                            >
                                <Radio value="json">JSON</Radio>
                                <Radio value="regexp">Регулярное выражение</Radio>
                            </RadioGroup>
                        )}
                    />
                </FormGroup>
            }

            {
                isExtendedMethod
                && isParseErrorsChecked
                && <FormGroup>
                    {
                        isParseErrorsJson
                            ? <label key="json_label">Путь к массиву <HintQuestion placement="right">
                                <>
                                    {/* eslint-disable-next-line max-len */}
                                    <p>Если страница отдает непосредственно JSON-массив проблем, то оставьте это поле пустым</p>
                                    {/* eslint-disable-next-line max-len */}
                                    <p>Если же JSON-массив проблем находится внутри объекта, то укажите путь к полю, в котором находится массив. Поддерживается точечная нотация. Пример: <strong>result.errors</strong>
                                    </p>
                                </>
                            </HintQuestion></label>
                            : <label key="regexp_label">Шаблон регулярного выражения <HintQuestion placement="right">
                                <>
                                    {/* eslint-disable-next-line max-len */}
                                    <p>Укажите шаблон регулярного выражения для получения списка проблем. Используйте именованную группу <strong>message</strong> для указания группы, содержащей сообщение о
                                        проблеме.
                                    </p>
                                    <p>Пример: <strong>/- (?&lt;message&gt;.+)(\r\n|\r|\n)?/</strong></p>
                                </>
                            </HintQuestion></label>
                    }
                    <InputText
                        defaultValue={item?.parse_errors_pattern}
                        name="parse_errors_pattern"
                        register={register}
                        control={control}
                    />
                </FormGroup>
            }

            {isExtendedMethod && MonitorContentExtraSetting({
                item: item,
                getValues: getValues,
                errors: errors,
                control: control,
                register: register,
                enabled: user?.tariff.monitor_content_enabled,
            })}
        </>
    );
};

export default ExtraSettingsSubform;